<template>
  <v-row class="fill-height pe-6 pe-md-0">
    <v-col cols="12">
      <v-tabs v-model="tab" background-color="transparent" fixed-tabs hide-slider show-arrows>
        <v-tab v-for="(step, index) in steps" :key="step.title"
               :class="{'first-tab': isFirstItem(index), 'last-tab': isLastItem(index)}"
               class="tab">
          {{ $vuetify.lang.t(step.title) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="step in steps" :key="step.number">
          <validation-observer ref="observer">
            <component v-bind:is="`KurccSiteStepperStep${step.number}Content`" :site.sync="site"/>
          </validation-observer>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import siteSteps from '@/modules/site/enums/site-steps'

export default {
  name: 'KurccProfilePage',
  components: {
    KurccSiteStepperStep1Content: () => import('@/modules/site/components/KurccSiteStepperStep1Content'),
    KurccSiteStepperStep2Content: () => import('@/modules/site/components/KurccSiteStepperStep2Content'),
    KurccSiteStepperStep3Content: () => import('@/modules/site/components/KurccSiteStepperStep3Content'),
    KurccSiteStepperStep4Content: () => import('@/modules/site/components/KurccSiteStepperStep4Content'),
    KurccSiteStepperStep5Content: () => import('@/modules/site/components/KurccSiteStepperStep5Content'),
    KurccSiteStepperStep6Content: () => import('@/modules/site/components/KurccSiteStepperStep6Content'),
    KurccSiteStepperStep7Content: () => import('@/modules/site/components/KurccSiteStepperStep7Content'),
    ValidationObserver
  },
  data () {
    return {
      siteSteps,
      tab: null,
      site: null,
      updatingSite: false
    }
  },
  computed: {
    steps () {
      const steps = { ...this.siteSteps }
      delete steps.submit
      delete steps.branch
      return steps
    }
  },
  methods: {
    cloneSite () {
      this.site = this.$lodash.cloneDeep(this.$auth.user().site)
    },
    updateSite () {
      this.updatingSite = true
      this.$store.dispatch('updateSite', { ...this.site })
        .then(() => {
          this.cloneSite()
        })
        .finally(() => {
          this.updatingSite = false
        })
    },
    isLastItem (index) {
      if (this.$vuetify.rtl) {
        return index === 'generalInfo'
      } else {
        return index === 'logo'
      }
    },
    isFirstItem (index) {
      if (this.$vuetify.rtl) {
        return index === 'logo'
      } else {
        return index === 'generalInfo'
      }
    }
  },
  created () {
    this.cloneSite()
    this.$emit('ref', this)
  }
}
</script>

<style lang="scss" scoped>
.tab {
  //min-width: 200px;
  border-left: solid 1px;
  border-top: solid 1px;
  border-bottom: solid 1px;
}

.first-tab {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.last-tab {
  border-right: solid 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
