export default Object.freeze({
  generalInfo: {
    number: 1,
    title: '$vuetify.pages.site.form.titles.generalInfo'
  },
  paci: {
    number: 2,
    title: '$vuetify.pages.site.form.titles.paci'
  },
  commercialLicence: {
    number: 3,
    title: '$vuetify.pages.site.form.titles.commercialLicense'
  },
  municipality: {
    number: 4,
    title: '$vuetify.pages.site.form.titles.municipality'
  },
  managingPartner: {
    number: 5,
    title: '$vuetify.pages.site.form.titles.managingPartner'
  },
  seniorStaff: {
    number: 6,
    title: '$vuetify.pages.site.form.titles.seniorStaff'
  },
  logo: {
    number: 7,
    title: '$vuetify.pages.site.form.titles.logo'
  },
  branch: {
    number: 8,
    title: '$vuetify.pages.site.form.titles.mainBranch'
  },
  submit: {
    number: 9,
    title: '$vuetify.pages.site.form.titles.finalizeAndSubmit'
  }
})
